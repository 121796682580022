@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Piazzolla:ital,opsz,wght@0,8..30,100;0,8..30,200;0,8..30,300;0,8..30,900;1,8..30,100;1,8..30,200;1,8..30,800;1,8..30,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100;8..144,300;8..144,400;8..144,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,800;1,100;1,200;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Piazzolla:ital,opsz,wght@0,8..30,100;0,8..30,200;0,8..30,300;0,8..30,900;1,8..30,100;1,8..30,200;1,8..30,800;1,8..30,900&family=Ultra&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Stylish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gajraj+One&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: blue;
  --secondary-color: teal;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0px;
}

.scroll ::-webkit-scrollbar {
  width: 3px;
  background: red;
  background: white;
  border-radius: 0px;
}

.form-row {
  @apply flex flex-col gap-x-5 gap-y-2 mb-3 lg:flex-row my-5;
}

.currency-input {
  @apply placeholder:text-slate-400 block text-lg bg-white w-full outline-none border border-slate-400 shadow-md rounded-md py-2 pl-9;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.PhoneInputInput:focus {
  outline: none;
}
